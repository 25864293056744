.site-nav {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 100%;
    z-index: 100;
    transition: all 0.5s ease-in-out;
    padding-top: 30px;
    position: relative;
    z-index: 28;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 28;
    }

    .site-logo {
      width:40px;
      height: 38px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        // box-shadow: 0px 0px 8px 0px #00ffc4;
        border-radius: 50%;
      }
      span {
        text-decoration: none;
        color: #fff;
        padding-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
    }
    .site-links-wrapper {
      display: flex;
      align-items: center;
    }
    .site-links {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin-left: auto;
      &.extended {
        margin: 0;
        padding-left: 1.75rem;
        z-index: 2;
      }
      li {
        margin-right: 0.5rem;
      }
      li:last-child {
        margin-right: 0rem;
      }

      a:last-child {
        padding-right: 0rem;
      }
      a {
        display: flex;
        mix-blend-mode: difference;
        text-decoration: none;
        color: #fff;
        padding: 0rem 0.875rem;
        font-size: 0.875rem;
        font-weight: 600;
        font-family: "Karla", sans-serif;
        cursor: pointer;
        position: relative;
        transition: all 300ms;
        &.active {
          color: #fff;
          &::after {
            content: "";
            position: absolute;
            bottom: 16px;
            left: 89%;
            transform: translateX(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--c-primary);
            // bottom: 0px;
            // left: 50%;
            // width: 30%;
            // height: 1px;
            // box-shadow: -1px 0px 6px #62e99e;
          }
        }
      }
    }
  }
