.sg-cursor{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index:26;
  width: 50px;
  height: 50px;
  pointer-events: none;
  user-select: none;
  mix-blend-mode: difference;

  .sg-cursor--outer,
  .sg-cursor--inner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  .sg-cursor--inner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    span.dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      transition: 0.3s ease;
    }
  }
  .sg-cursor--txt,.sg-cursor--click{
    position: absolute;
    top: 10px;
    left: 50px;
    visibility: hidden;
    font-weight: 600;
    color: #fff;
    mix-blend-mode: difference;
  }
  .sg-cursor--outer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 40px;
    height: 40px;
    // mix-blend-mode: difference;
    border-radius: 50%;
    svg {
      width: 100%;
      height: 100%;
      circle {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sg-cursor.cursor-link {
  .sg-cursor--outer {
    opacity: 0!important;
   }
   .sg-cursor--inner {
    width: 70px;
    height: 70px;
    mix-blend-mode: difference;
    span.dot  {
      display: flex;
       width: 70px;
      height: 70px;
      background-color: #111;
      // border:1px solid #fff;
      // background-color: transparent;
      mix-blend-mode: difference;
    }
   }
}

.sg-cursor.cursor-player {
  .sg-cursor--outer {
    opacity: 0!important;
   }
   .sg-cursor--inner {
    width: 70px;
    height: 70px;
    mix-blend-mode: difference;
    span.dot  {
      display: flex;
       width: 70px;
      height: 70px;
      background-color: #111;
      // border:1px solid #fff;
      // background-color: transparent;
      mix-blend-mode: difference;
    }
   }
   .sg-cursor--txt{
     visibility: visible;
   }
}

.sg-cursor.cursor-txt {
  .sg-cursor--outer {
    opacity: 0!important;
   }
   .sg-cursor--inner {
    width: 70px;
    height: 70px;
    mix-blend-mode: difference;
    span.dot  {
      display: flex;
       width: 70px;
      height: 70px;
      background-color: #111;
      // border:1px solid #fff;
      // background-color: transparent;
      mix-blend-mode: difference;
    }
   }
   .sg-cursor--click{
     visibility: visible;
   }
}

