.flex {
  display: flex;
  &.fw {
    &-w {
      flex-wrap: wrap;
    }
    &-wr {
      flex-wrap: wrap-reverse;
    }
  }
  &.ju-c {
    &-sb {
      justify-content: space-between;
    }
    &-c {
      justify-content: center;
    }
    &-se {
      justify-content: space-evenly;
    }
  }
  &.al-i {
    &-c {
      align-items: center;
    }
    &-fe {
      align-items: flex-end;
    }
    &-fs {
      align-items: flex-start;
    }
  }
}
.h100 {
  height: 100%;
}
.grid {
  display: grid;
}

.block {
  display: block;
}

.spacer {
  display: block;

  height: clamp(0.5rem, 0.8vw, 1rem);
  &.inline {
    display: inline-block;

    width: clamp(0.5rem, 0.8vw, 1rem);
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.h100 {
  height: 100%;
  &-vh {
    height: 100vh;
  }
}

.h60 {
  height: 100%;
  min-height: 60%;
  &-vh {
    height: 100%;
    min-height: 60vh;
  }
}

@for $i from 1 through 8 {
  .bg-accent-#{$i} {
    background-color: var(--c-accent-#{$i});
  }
}

@for $i from 1 through 10 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
  .pt-#{$i} {
    padding-top: #{$i}rem;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}rem;
  }
}

.sm-fluid {
  @include breakpoint-down('sm') {
    width: 100%;
  }
}

.hidden-mobile {
  @include breakpoint-down('sm') {
    display: none;
  }
}

.hidden-desktop {
  @include breakpoint-up('lg') {
    display: none;
  }
}

.hidden-tablet {
  @include breakpoint-up('md') {
    display: none;
  }
}

.sp {
  padding: 2rem 0;
  padding-top: calc(3rem + 60px);
}

.bg-gradient {
  position: relative;
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    background: linear-gradient(180deg, #000 0, transparent);
    height: 15vh;
    width: 100%;
    z-index: 25;
    pointer-events: none;
  }
}
