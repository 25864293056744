.work {
  display: block;
  width: 100%;
  min-height: 100vh;
}
.work-lists {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  // grid-auto-rows: auto;
  // row-gap: 2rem;
  // column-gap: 2rem;
  @include breakpoint-down('sm') {
    grid-template-columns: 1fr;
  }
}
img {
  max-width: 100%;
}
.work-item {
  border-bottom: 1px solid #ddd;
  text-align: center;
  &--img {
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &--title {
    margin: 1rem 0rem;
  }
  @include breakpoint-down('sm') {
    grid-template-columns: 1fr;
    &--img {
      height: auto;
    }
  }
}

.work-detail {
  pointer-events: all;
  &--thumb {
    height: 80vh;
    padding-bottom: 0rem !important;
    background-color: #00251c;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top center;
    }
  }
  &--intro {
    min-height: 40vh;
    background-color: #000;
    padding: 2rem 0rem;
    color: #fff;
    h2 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: var(--c-primary);
    }
    a {
      display: inline-block;
      color: #fff;
      margin: 1rem 0rem;
      pointer-events: all;
      cursor: pointer;
    }
    p {
      font-size: 0.875rem;
    }
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      sub {
        font-weight: bold;
        margin-right: 1rem;
        font-size: 0.875rem;
        color: #f2f2f2;
      }
      font-size: 1.2rem;
      color: var(--c-primary);
    }
  }
  &--fluid {
    height: 80vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // object-position: bottom right;
    }
  }
  &--content {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
    figure {
      display: block;
      width: 100%;
      height: 100%;
      // max-height: 100vh;
      padding-top: 1rem;
      padding-bottom: 4rem;
      border: 1px solid #000;
      border-bottom: 0px;
      figcaption {
        // margin-bottom: 0.875rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
        font-size: 1rem;
        font-weight: 600;
        border-bottom: 1px solid #000;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
