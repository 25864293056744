  .hero{
      display: block;
      width: 100%;
      color: #fff;
      height: 100vh;
      position: relative;
      // z-index: 20;
      .wrapper{
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
      &--content{
        display: block;
        position: relative;
        z-index: 25;
        // padding-bottom:20px;
        h1{
          font-size: clamp(2.5rem, 5vw, 3.875rem);
          line-height: 0.9;
          color: #fff;
        }
      }
      &--desc{
        display: block;
        width: 50vw;
        margin-top: 1.5rem;
        line-height: 1.7;
        word-wrap: break-word;
        color: #f2f2f2;
      }
      &--img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        mix-blend-mode: difference;
      }
      &--music{
        position: absolute;
        bottom: 30px;
        left: 0;
        padding-left: 1.2rem;
        z-index: 26;
        button{
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #fff;
          background: transparent;
          color: #fff;
          cursor: pointer;
          pointer-events: all;
          outline: none;
        }
      }
      &--footer {
        position: absolute;
        right: 0;
        bottom: 30px;
        width: auto;
        display: inline-block;
   
        &-content {
          display: flex;
          justify-content: flex-end;
        }
      }
      &--footer-item:last-child{
        margin-right: 0.9375rem;
         }
      &--footer-item {
        margin-right: 1.5rem;
      
        p {
          font-size: 0.875rem;
          &.sub {
            color: #888;
            margin-bottom: 0.5rem;
            // color: ${(props) => props.theme.color.accent5};
          }
          &.sub-item {
            display: flex;
            align-items: center;
            svg {
              height: 0.75rem;
              margin-right: 0.5rem;
            }
          }
        }
      }

      @include breakpoint-down("sm"){
          height:100vh;
        &--desc{
            display: block;
            width: 100%;
          }
      }
  }
  