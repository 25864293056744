/**
    Name: CUSTOM GRID SYSTEM 
    Author: Suprim Golay
    Author Uri: https://suprimgolay.com.np
    Description: Based on bootstrap grid system without unneccessary code. Note ClassNames doesn;t conflict
    with any default classes.
    ClassNames Used: wrapper, wrapper-fluid, wrapper-row, wrap, wrap-sm-1; 
    Mixins Available: [
        @include breakpoints-up/down("sm/md/lg/xl"){...}
    ]
    Version: 1.0
    License: FREE TO USE -- @ -- Credit Required.
**/

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$columns: 12;
$gutter: 30;

// BreakPoints Mixin
// 👆🏻 // sm: 576px, // md: 768px, // lg: 992px, // xl: 1200px, // xxl: 1400px
// 👇🏻 // sm: 575px, // md: 767px, // lg: 991px, // xl: 1199px, // xxl: 1399px
$breakpoints-up:("sm":"36em","md":"48em","lg":"62em","xl":"75em","xxl":"87.5em");
$breakpoints-down:("sm":"35.9375em","md":"47.9375em","lg":"61.9375em","xl":"87.4375em");

@mixin breakpoint-up($size) {
    @media (min-width: map-get($breakpoints-up, $size )) {
        @content;
    }
}

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size )) {
        @content;
    }
}


.wrapper{
    display: block;
    width: 100%;
    padding-left: rem-calc( $gutter / 2);
    padding-right:rem-calc( $gutter / 2) ;
    margin-right: auto;
    margin-left: auto;
}

.wrapper-fluid{
    width: 100%;
    padding-right: rem-calc($gutter / 2);
    padding-left: rem-calc($gutter / 2);
    margin-right: auto;
    margin-left: auto;
}

.wrapper-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: - rem-calc($gutter / 2);
    margin-left: - rem-calc($gutter / 2);
}

.wrap {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

[class*="wrap-"] {
    position: relative;
    width: 100%;
    padding-right: rem-calc($gutter / 2);
    padding-left: rem-calc($gutter / 2);
}

@for $i from 1 through $columns {
    .wrap-#{$i} {
        flex: 0 0 calc(#{$i/$columns*100 + '%'});
        max-width : calc(#{$i/$columns*100 + '%'});
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    .wrapper {
        max-width: 640px;
    }

    @for $i from 1 through $columns {
        .wrap-sm-#{$i} {
            flex: 0 0 calc(#{$i/$columns*100 + '%'} );
            max-width: calc(#{$i/$columns*100 + '%'} );
        }
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .wrapper {
        max-width: 720px;
    }

    @for $i from 1 through $columns {
        .wrap-md-#{$i} {
            flex: 0 0 calc(#{$i/$columns*100 + '%'} );
            max-width: calc(#{$i/$columns*100 + '%'} );
        }
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .wrapper {
        max-width: 992px;
    }

    @for $i from 1 through $columns {
        .wrap-lg-#{$i} {
            flex: 0 0 calc(#{$i/$columns*100 + '%'} );
            max-width: calc(#{$i/$columns*100 + '%'} );
        }
    }
    
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .wrapper {
        max-width: 1200px;
    }

    @for $i from 1 through $columns {
        .wrap-xl-#{$i} {
            flex: 0 0 calc(#{$i/$columns*100 + '%'} );
            max-width: calc(#{$i/$columns*100 + '%'} );
        }
    }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    .wrapper {
        max-width: 1450px;
    }

    @for $i from 1 through $columns {
        .wrap-xxl-#{$i} {
            flex: 0 0 calc(#{$i/$columns*100 + '%'} );
            max-width: calc(#{$i/$columns*100 + '%'} );
        }
    }
 }