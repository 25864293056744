@keyframes pulse {
    0%{transform: translate(-50%,-50%) scale(.1);opacity: 0;}
    50%{ opacity: .4;}
    100%{transform: translate(-50%,-50%) scale(1.6); opacity: 0;}
}
@keyframes sake {
    0%{transform: translate(-50%,-50%) scale(.1);opacity: 0;}
    50%{ opacity: .4;}
    100%{transform: translate(-50%,-50%) scale(1.6); opacity: 0;}
}



.footer-music{
    position: fixed;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    bottom: 40px;
    height:50px;
    z-index: 27;
    pointer-events: all;
    outline: none;
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .music--btn{
        &.active{
            span.center{
                animation: sake 1s infinite;
                // transition: 300ms ease;
            }
            span.pulse{
                 animation: pulse 1.5s infinite;
            }
            span.pulse2{
                animation: pulse 3s infinite;
             }
        }
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            background:transparent;
            border:none;
            outline:none;
            position: relative;
            width: 50px;
            height:50px;
        }
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        span.center{
            display: flex;
            width: 5px;
            height: 5px;
            background-color: #f1f1f1;
            border-radius: 50%;
            // transition: all ease;
        }
        span.pulse{
            display: flex;
            width:15px;
            height:15px;
            border:0.2px solid #f1f1f1;
            border-radius: 50%;
        }
        span.pulse2{
            display: flex;
            width:25px;
            height:25px;
            border:0.2px solid #f1f1f1;
            border-radius: 50%;
        }
    }
}