// ### 01 Generic
@import './01-generic/normalize'; // @ Optional
@import './01-generic/reset'; // @ Optional
// ### 02 Settings
@import './02-settings/global'; // @ required
// ### 03 Tools
@import './03-tools/functions'; // @ Optional // @ required for 04 Layouts
// ### 04 Layouts
@import './04-layouts/grid'; // @contected with 03 Tools
// ### 06 Components
@import './05-components/button'; // @ Optional
@import './05-components/nav'; // @ Optional
@import './05-components/input'; // @ Optional
@import './05-components/card'; // @ Optional
@import './05-components/slider'; // @ Optional
@import './05-components/cursor'; // @ Optional
@import './05-components/resume'; // @ Optional
@import './05-components/bg_stars'; // @ Optional
@import './05-components/preloader'; // @ Optional
@import './05-components/hero'; // @ Optional
@import './05-components/r3f'; // @ Optional
@import './05-components/work'; // @ Optional
@import './05-components/music'; // @ Optional
@import './05-components/footer'; // @ Optional
@import './05-components/page_trans'; // @ Optional
// @import './05-components/scroll'; // @ Optional
// ### 05 Utils
@import './06-utils/utils'; // @ Optional

.center {
  // height: 100vh;
  // display: flex;
  // align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.custom-sliderwrapper {
  max-width: 1500px;
  margin:0 auto;
}
.slider--container {
  display: block;
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 26;
  user-select: none;
  @media (max-width: 768px) {
    height: 450px;
  }
}

.slider {
  position: relative;
  height: 100%;
  //   width: 100%;
  cursor: grab;
  &.is-grabbing {
    cursor: grabbing;
  }
  &--inner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translate3d(0px, 0px, 0px);
  }
  &--item {
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: 100px;
    left: 50%;
    transform: translateX(-50%);
    img {
      pointer-events: none;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

.slider--text {
  position: absolute;
  bottom: 10rem;
  left: 200px;
  z-index: 999;
  min-height: 60px;
  &-item {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 60px;
    overflow: hidden;
    visibility: hidden;
    &.active {
      visibility: visible;
      h2 {
        pointer-events: all;
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }
  h2 {
    white-space: nowrap;
    font-size: 6rem;
    color: #fff;
    transition: all 0.5s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    user-select: none;
    a {
      display: block;
      width: 100%;
      color: #fff;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    left: 1rem;
    min-height: 30px;
    h2 {
      white-space: nowrap;
      font-size: 3rem;
    }
  }
}

.slide--content {
  position: relative;
  display: block;
  width: 100%;
  min-width: 850px;
  height: 100%;
  user-select: none;
  pointer-events: all;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
  @media (max-width: 768px) {
    min-width: 600px;
  }
}

#webgl canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background: transparent;
}
