.page-trans{
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    pointer-events: none;
    svg{
        position: absolute; 
        top: -1px; 
        left: -1px; 
        pointer-events: none;
    }
}