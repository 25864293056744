.preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 50;
    user-select: none;
    &--content{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding:0rem 0.5rem;
        display: flex;
    }
    &--title{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 500px;
        text-align: center;
        .line-h3{
            display: block;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            h3{
                opacity: 0;
                transform: translateY(40px);
                will-change: transform opacity;
            }
        }
    }
}
.preloader--trailer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-primary);
    z-index: 40;
    user-select: none;
}

.music{
    position: absolute;
    top: 60%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-60%);
    display: flex;
    flex-direction: column;
    align-items: center;
}