.resume-content {
  position: relative;
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    background: linear-gradient(180deg, #000 0, transparent);
    height: 15vh;
    width: 100%;
    z-index: 25;
    pointer-events: none;
  }
}
.resume-intro {
  padding: 2rem 0;
  padding-top: calc(3rem + 60px);

  .name-txt {
    letter-spacing: 0.008rem;
    text-shadow: -2px 0 #0d1b1e, 0 -2px #0d1b1e, 2px 0 #0d1b1e, 0 2px #0d1b1e,
      2px 2px #0d1b1e, -2px -2px #0d1b1e, -2px 2px #0d1b1e, 2px -2px #0d1b1e,
      7px 7px #00f4c5;
    transition: all 0.2s ease;
    &:hover {
      text-shadow: -2px 0 #0d1b1e, 0 -2px #0d1b1e, 2px 0 #0d1b1e, 0 2px #0d1b1e,
        2px 2px #0d1b1e, -2px -2px #0d1b1e, -2px 2px #0d1b1e, 2px -2px #0d1b1e,
        -10px -10px salmon;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    a {
      color: var(--c-primary);
      text-decoration: none;
    }
  }
}
.resume-item {
  padding: 3.2rem 0;
  @media (max-width: 768px) {
    padding: 1.5rem 0rem;
    .resume-title {
      margin-bottom: 1rem;
    }
  }
}
.profile-right {
  p {
    line-height: 1.5;
  }
}
.resume-item.skills {
  p {
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    font-size: 1rem;
    font-weight: 600;
  }
  ul {
    margin-left: 1rem;
    font-size: 0.875rem;
    li {
      margin-bottom: 0.5rem;
      ul {
        margin-top: 0.5rem;
        display: flex;
        li {
          &:first-child {
            margin-left: 0rem;
          }
          margin-left: 2rem;
          margin-bottom: 0rem;
        }
      }
    }
  }
}
.experience-item {
  margin-bottom: 1rem;
  h3 {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    color: var(--c-primary);
  }
  ul {
    margin-left: 1rem;
    font-size: 0.875rem;
    li {
      margin-bottom: 0.2rem;
    }
  }
}
.education-item {
  margin-bottom: 1rem;
  h3 {
    margin-bottom: 0.5rem;
  }
  a {
    text-decoration: none;
    color: var(--c-primary);
  }
}
