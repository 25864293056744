.su-star{
    user-select: none;
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0px;
    transform: translateY(-100%);
    z-index: 20;
    pointer-events: none;
  }
  