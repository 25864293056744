.portfolio-slider {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  .wrapper {
    position: relative;
  }
  &--content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 70vh;
    z-index: 26;
    // overflow: hidden;
    user-select: none;
  }
  &--item {
    width: 100%;
    min-width: 850px;
    height: 100%;
    user-select: none;
    pointer-events: all;
    // overflow: hidden;
    // pointer-events: none;
    img {
      visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // z-index: -2;
    }
    &-link {
      display: none;
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #fff;
      visibility: hidden;
    }
    &-title {
      position: absolute;
      bottom: 40px;
      left: -40px;
      color: #fff;
      h2 {
        font-size: 5.75rem;
        font-weight: 800;
        line-height: 0.9;
        // font-family: Verdana, Geneva, Tahoma, sans-serif;
      }
    }
  }

  @include breakpoint-down('sm') {
    &--item {
      width: calc(100vw - 15px);
      min-width: calc(100vw - 15px);
      img {
        height: 250px;
      }

      &-title {
        position: absolute;
        bottom: 40px;
        left: 20px;
        color: #fff;
        h2 {
          font-size: clamp(1.7rem, 5vw, 2.5rem);
        }
      }
    }
  }
}

.su-slider {
  position: relative;
  height: 100%;
  width: 100%;
  // overflow: hidden;
  cursor: grab;
  &.is-grabbing {
    cursor: grabbing;
  }
  &--wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translate3d(0px, 0px, 0px);
  }
  &--slide {
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: 100px;
    left: 50%;
    transform: translateX(-50%);
    &:last-child {
      margin-right: 0px;
    }

    &.active {
      .portfolio-slider--item-link {
        display: flex;
        visibility: visible;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          width: 100%;
          height: 100%;
          font-weight: bold;
          color: #fff;
          font-size: 0.875rem;
          mix-blend-mode: difference;
          font-family: var(--f-family-primary);
        }
      }
    }
  }
  &--nav {
    button {
      background: transparent;
      cursor: pointer;
      border: none;
      color: #fff;
      font-size: 0.875rem;
      margin: 0rem 1.5rem;
      font-weight: 600;
      outline: none;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0rem;
      }
      img {
        height: 16px;
      }
    }
  }
  &--dots {
    ul {
      list-style: none;
      display: flex;
      li {
        margin-right: 1rem;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #fff;
        border: 1px solid #fff;
        font-size: 0.875rem;
        cursor: pointer;
        border-radius: 50%;
        &.active {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
  &--actions {
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 100%;
    z-index: 28;
    .wrapper {
      position: relative;
    }
    &--content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

// #webgl canvas{
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 26;
//     background: transparent;
// }

// #webglbg{
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -1;
// }
