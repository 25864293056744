.fiber {
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 3;
}


  #scrollArea{
    // pointer-events: none;
    // user-select: none;
    z-index: 4;
  }
  
  .hero{
    pointer-events: none;
    user-select: none;
    // z-index: 2;
  }

  .hero--footer{
    pointer-events: all!important;
    // user-select: all !important;
    // z-index: 3;
  }
  .su-slider--actions{
    pointer-events: all!important;
    // user-select: non !important;
  }

  @media (max-width:768px){
    .fiber {
      z-index: -1;
    }
  }
  