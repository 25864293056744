.card{
    border: 1px solid var(--c-accent-2);
    border-radius: var(--b-radius);
    margin-bottom: 1rem;
    background-color: var(--c-background);
    box-shadow: var(--shadow-1);
    transition: 0.3s;
    &--content{
        padding: 1rem;
     
        p{
            color: var(--c-accent-5);
        }
    }
}