.btn{
    padding: 0.875rem 1.875rem;
    border-radius: var(--b-radius);
    border: 1px solid var(--c-accent-4);
    background-color: var(--c-background);
    font-weight: var(--f-weight-semibold);
    outline: none;
    transition: all 0.3s;
    
    &.primary{
        background-color: var(--c-primary);
        color: #ffffff;
        border-color: var(--c-primary);
        &:hover{
            background-color: var(--c-primary-dark);
        }
    }
}

