html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
form,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html {
	box-sizing: border-box;
}

* {

	&,
	&::before,
	&::after {
		box-sizing: inherit;
	}
}

button {
	cursor: pointer;
}
