input{
    display: flex;
    width: 100%;
    height: 45px;
    padding: 0.75rem 1.5rem;
    border-radius: var(--b-radius);
    border: 1px solid var(--c-accent-2);
    background-color: var(--c-background);
    color: var(--c-foreground);
    font-size: var(--f-size-small);
}