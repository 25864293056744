@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,600;1,900&family=Poppins:wght@400;500;600;700;800;900&display=swap');


:root{
    // FONTS
    --f-family-primary:"Karla", sans-serif; 
    --f-family-secondary:"Montserrat", sans-serif; 
    --f-size-h1:clamp(2.5rem,10vw,2.875rem);
    --f-size-h2:clamp(1.5rem,8vw,1.75rem);
    --f-size-h3:clamp(1.125rem,6vw,1.25rem);
    --f-size-h4:clamp(1rem,4vw,1.5rem);
    --f-size-h5:clamp(0.875rem,4vw,1rem);
    --f-size-h6:0.875rem;
    --f-size-p:1rem;
    --f-size-small:0.875rem;
    --f-size-btn:0.975rem;
    --f-size-nav:0.875rem;
    // FONT WEIGHT
    --f-weight-bold:800;
    --f-weight-semibold:600;
    --f-weight:400;
    //LINE HEIGHT
    --line-height-heading:inherit;
    --line-height-body:inherit;
    // BORDER
    --b-radius:5px;
    // SHADOW
    --shadow-1:0px 2px 3px rgba(0,0,0,0.1);
    // COLORS
    --c-background:rgb(255, 255, 255);
    --c-foreground:#000;
    --c-accent-1:#fafafa;
    --c-accent-2:#eaeaea;
    --c-accent-3:#999;
    --c-accent-4:#888;
    --c-accent-5:#666;
    --c-accent-6:#444;
    --c-accent-7:#333;
    --c-accent-8:#111;
    --c-primary:#00f4c5;
    --c-primary-light:#14facc;
    --c-primary-dark:#01cfa6;
    --c-secondary:rgb(0, 187, 115);
    --c-secondary-light:#0ab;
    --c-secondary-dark:#0ab;
    --c-success:rgb(0, 238, 167);
    --c-success-light:#88fca1;
    --c-success-dark:#018035;
    --c-error:#E00;
    --c-error-light:#FF1A1A;
    --c-error-dark:#C50000;
    --c-warning:#F7B955;
    --c-warning-light:#F5A623;
    --c-warning-dark:#AB570A;
}

$base-font-size : 16px;

body{
    font-size:$base-font-size;
    background: var(--c-background);
    color: var(--c-foreground);
    font-family: var(--f-family-primary);
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

#scrollArea{
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

body.dark-theme {
       // COLORS
        --c-background:#000;
        --c-foreground:#fff;
        --c-accent-1:#111;
        --c-accent-2:#333;
        --c-accent-3:#444;
        --c-accent-4:#666;
        --c-accent-5:#888;
        --c-accent-6:#999;
        --c-accent-7:#EAEAEA;
        --c-accent-8:#FAFAFA;
}




@for $i from 1 through 6{
    h#{$i}{
        font-size: var(--f-size-h#{$i});
    }
}

h1,h2,h3,h4,h5,h6{
    font-family: var(--f-family-secondary);
    line-height: var(--line-height-heading);
    margin-bottom: 0.215rem;
}

p,button,span{
    font-family: var(--f-family-primary);
    line-height: var(--line-height-body);
}
p{
    font-size: var(--f-size-p);
}

small,span{
    font-size:  var(--f-size-small);
}

button{
    font-size: -var(--f-size-btn);
}
// CUSTOM SCROLLBAR
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #0000;
}
::-webkit-scrollbar-thumb {
    background:rgb(94, 94, 94);
}